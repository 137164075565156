<template>
  <div :class="`${extraClass} ${extraClass}_${lang}`" class="bn-container">
    <div class="bn-info">
      <h3>
        <span v-html="$t(title)" :dir="ltr ? 'ltr' : ''"></span>
      </h3>
    </div>
    <div v-if="rightSideImg" class="r-side-img">
      <img :src="rightSideImg" alt />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannerItem',
  props: {
    extraClass: String,
    title: String,
    rightSideImg: String,
    lang: String,
    ltr: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/banner/v1/bannerItem.scss';
@import '@/assets/css/components/banner/v1/xmasMakeAWishBanner.scss'; // xmas make a wish promotion
</style>
