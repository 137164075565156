<template>
  <div class="table-wrapper">
    <el-row :gutter="20">
      <el-col :span="24">
        <div class="table-box">
          <el-table :data="displayData">
            <el-table-column prop="date" :label="$t('promotion.depositBonus.depositDate')" min-width="150">
              <template slot-scope="scope">
                <img src="@/assets/images/promotion/FTD.png" alt class="ftd" v-if="scope.row.type" />{{
                  scope.row.depositDate | dateYearLast
                }}</template
              >
            </el-table-column>
            <el-table-column :label="$t('common.column.tradingAcc')" min-width="120">
              <template slot-scope="scope"> {{ scope.row.mt4Account }}({{ scope.row.currency }}) </template>
            </el-table-column>
            <el-table-column prop="amount" :label="$t('common.column.amt')" min-width="120">
              <template slot-scope="scope"
                >{{ scope.row.currency | currencyToSymbol }}{{ scope.row.actualAmount | formatNumber }}</template
              >
            </el-table-column>
            <el-table-column :label="$t('promotion.depositBonus.credit')" min-width="120">
              <template slot-scope="scope">{{ getWelcomeCredit(scope.row) }}</template>
            </el-table-column>
          </el-table>
        </div>
        <Pagination
          :table-data="depositBonusData"
          :display-data.sync="displayData"
          :customizedPageSize="pageSize"
        ></Pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination';

export default {
  name: 'CredictReceived',
  props: { tableData: Array, welcomeRateLimit: Number },
  components: { Pagination },
  data() {
    return { displayData: [], pageSize: 10 };
  },
  computed: {
    welcomeBonusIndex() {
      let welcomeBonus = this.tableData.find(element => element.type);
      return this.tableData.length > 0 ? this.tableData.indexOf(welcomeBonus) : null;
    },
    welcomeBonusData() {
      return this.welcomeBonusIndex !== -1 ? [this.tableData[this.welcomeBonusIndex]] : [];
    },
    depositBonusData() {
      let data = this.tableData.length > 0 ? this.tableData.filter((e, i) => i !== this.welcomeBonusIndex) : [];
      return [...this.welcomeBonusData, ...data];
    }
  },
  methods: {
    getWelcomeCredit(row) {
      let val = row.creditAmount;
      let currency = row.currency;
      let baseAmount = this.welcomeRateLimit;
      let symbol = this.$options.filters.currencyToSymbol(currency);
      if (this.tableData.indexOf(row) !== this.welcomeBonusIndex) {
        return `${symbol}` + val;
      } else {
        return val <= baseAmount ? `${symbol}` + val : `${symbol}${baseAmount} + ` + `${symbol}${val - baseAmount}`;
      }
    }
  }
};
</script>
